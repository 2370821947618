export const loadInternalFont = (fontName, fontFile) => {
  if (!fontName || !fontFile) return
  // Enable preload link when we have HTTP/2 or HTTP/3 - local files only
  const styleTag = document.createElement('style')
  styleTag.appendChild(
    document.createTextNode(`
        @font-face {
            font-family: '${fontName}';
            src: url('${fontFile}');
        }`)
  )
  document.head.appendChild(styleTag)
}

export const loadExternalFont = (fontUrl) => {
  if (!fontUrl) return

  const isGoogleFont = fontUrl.includes('fonts.googleapis.com')

  if (isGoogleFont) {
    //Preconnect to Google Fonts
    const preconnectTag = document.createElement('link')
    preconnectTag.href = 'https://fonts.gstatic.com' // Google serves fonts from this domain
    preconnectTag.rel = 'preconnect'
    preconnectTag.crossOrigin = ''
    document.head.appendChild(preconnectTag)

    //Preload Google Fonts CSS
    const preloadTag = document.createElement('link')
    preloadTag.href = fontUrl
    preloadTag.rel = 'preload'
    preloadTag.as = 'style'
    document.head.appendChild(preloadTag)
  }

  const linkTag = document.createElement('link')
  linkTag.href = fontUrl
  linkTag.type = 'text/css'
  linkTag.rel = 'stylesheet'
  linkTag.preload = 'true'
  document.head.appendChild(linkTag)
}
